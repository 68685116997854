<template>
  <div class="flex justify-between w-full">
    <SelaDialouge
      width="auto"
      :model-value="modelValue"
      :with-vmodel="true"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #content>
        <div class="p-2 sela-container w-full h-[85vh]">
          <v-locale-provider :rtl="useIsRtl()" class="w-full">
            <SelaLoadingIndicator
              v-if="loading && !material"
              :loading="true"
              color="border-nav_color"
              class="text-nav_color h-36 w-36"
            />
            <SelaFetchError
              v-else-if="error"
              :message="t('fetch_failed', { data: t('materials') })"
              @click="emit('update:modelValue', false)"
            />

            <div
              v-else-if="material"
              :key="`ddd${material.id}`"
              class="bg-bg_alt_color w-full flex flex-col md:flex-row rounded-md overflow-hidden shadow mb-5"
            >
              <MaterialsShowImageSlider :material="material" />
              <MaterialsShowMeta
                :material="material"
                :is-dialouge="true"
                @added-cart-success="emit('update:modelValue', false)"
                @refresh-page="fetchMaterial"
              />
            </div>
          </v-locale-provider>
        </div>
      </template>
    </SelaDialouge>
  </div>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type {
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'
const { materialModel, isAuthLoggedIn } = useMenuModel()
const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  materialId: number
  modelValue: boolean
  firstPropertyLookup?: PropertiesLookupValue
}>()

let material = ref<Material | null>(null)
const loading = ref<boolean>(false)
let error = ref<FetchError | null>(null)
const { t } = useI18n()
await fetchMaterial()

async function fetchMaterial(newIdMaterial?: number) {
  const branchId = useBranchId()
  loading.value = true

  const {
    data: materialTemp,
    error: errorTemp,
    pending
  } = await useNuxtApiFetch(
    isAuthLoggedIn() ? '/user/materials/material' : '/laravel/material',
    {
      query: {
        id: newIdMaterial || props.materialId,
        branch_id: branchId,
        domain: useDomainHeader()
      },

      transform: (data: SuccessResponse<Material>) => {
        return materialModel(data.data)
      }
    }
  )
  error = errorTemp
  loading.value = pending.value
  material = materialTemp
}
</script>
