<template>
  <RikazDropdown position="mt-2 end-0" :fixed="false">
    <template #trigger="{ open }">
      <div class="flex items-center cursor-pointer" @click="openRate(open)">
        <div class="ml-2">{{ averageRate || 0 }}</div>
        <template v-for="star in 5" :key="`show-material-star-${star}`">
          <v-icon v-if="star <= filledRateStars" icon="fa: fa-solid fa-star" :size="12" class="mx-px text-rate" />
          <v-icon v-else icon="fa: fa-regular fa-star" :size="12" class="mx-px text-sela-light" />
        </template>
      </div>
    </template>
    <template #content="{ close }">
      <div class="p-3 w-64 sm:w-72 bg-nav_color bg-opacity-80">
        <div>
          <div class="flex justify-center">
            <RikazStarRating v-model="stars" :length="5" />
          </div>
          <SelaTextareaField v-model="note" class=" mt-1" :placeholder="t('rate')" error="" />
          <SelaResponseError
            class="my-2 text-xs"
            api-key="rate_material"
            :with-message="false"
            :except="['note']"
          />
          <div class="w-20 text-sm mx-auto">
            <SelaSubmitButton
              :title="t('submit')"
              type="button"
              radius="rounded-lg"
              :loading="loadingRate"
              @click="toggleRate(close)"
            />
          </div>
        </div>
      </div>
    </template>
  </RikazDropdown>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
const props = withDefaults(
  defineProps<{
    averageRate?: number
    materialId: number
  }>(),
  {
    averageRate: undefined
  }
)

const { loadingRate, rate } = useRate(props.materialId)

const note = ref<string>('')

const { status } = useAuth()
const toast = useToast()
const { t } = useI18n()
const stars = ref(0)
const emit = defineEmits(['refresh-page'])
const filledRateStars = computed(() => {
  return Math.round(props.averageRate || 0)
})

async function toggleRate(close: () => void) {
  const isRated = await rate(stars.value, note.value)
  if (isRated) {
    close()
    emit('refresh-page')
  }
}
function openRate(open: () => void) {
  if (status.value === 'authenticated') {
    open()
  } else {
    toast.error(t('login_required'), { timeout: 1000 })
  }
}
</script>
