<template>
  <SelaFieldError v-slot="{ hasError }" :name="error" class="mb-3">
    <div class="relative mt-2">
      <textarea
        class="text-center"
        :class="[{ error: hasError },classText]"
        v-bind="$attrs"
        :disabled="disabled"
        :value="modelValue"
        type="text"
        @input="updateValue"
      />
    </div>
  </SelaFieldError>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string
    classText?: string
    disabled: boolean
    error?: string | undefined
  }>(),
  {
    classText: 'sela-text-field',
    disabled: false,
    error: undefined
  }
)

const emit = defineEmits(['update:modelValue'])

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}
</script>
