<template>
  <div class="w-full md:w-1/2 p-1">
    <SelaSwiper
      :key="`swiper-ImageSlider`"
      :thumb="materialMedias.length > 1"
      thumb-slide-class="temp-slide"
      :items="materialMedias"
      :auto-play="false"
      @slide-change="selectedIndex = $event"
    >
      <template #slide="{ item, index }">
        <div
          v-if="index == selectedIndex"
          class="relative overflow-hidden mx-auto max-w-xs sm:max-w-lg md:max-w-2xl"
        >
          <iframe
            v-if="item?.url"
            :src="getLink(item)"
            :title="material.title"
            class="w-full aspect-square rounded-md overflow-hidden object-cover"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
          <NuxtImg
            v-else-if="item?.image"
            provider="cacheIpx"
            preset="modified"
            :src="item?.image"
            :alt="item?.image"
            width="300"
            height="300"
            :sizes="`sm:500px`"
            class="w-full h-full aspect-square rounded-md overflow-hidden object-cover"
          />

          <div
            v-if="material.discountRate"
            class="absolute top-7 ltr:-left-10 rtl:-right-10 px-1 bg-nav_color text-text_alt_color text-center whitespace-nowrap w-40 py-1 rotate-45"
          >
            <small>{{
              t('discount_percentage', { value: parseInt(material.discountRate) })
            }}</small>
          </div>
        </div>
      </template>

      <template #thumb="{ item, index }">
        <div
          :class="{
            'p-0.5 border-nav_color border-2 rounded-lg': selectedIndex == index
          }"
        >
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            :src="item.image || item.video?.image"
            :alt="item.image || item.video?.image"
            width="64"
            height="64"
            class="w-16 h-16 object-cover object-center rounded-md"
          />
        </div>
      </template>
    </SelaSwiper>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'

const props = defineProps<{
  material: Material
}>()

const selectedIndex = ref(0)
const { t } = useI18n()

function getLink(video: {
  id: string
  url: string
  image: string
  title: string
  provider: string
}) {
  if (video.provider === 'youtube') {
    return `https://www.youtube.com/embed/${video.id}`
  } else if (video.provider === 'vimeo') {
    return `https://player.vimeo.com/video/${video.id}`
  }
  return video.url
}
const materialMedias = computed(() => {
  const arrayTemp = [] as any
  arrayTemp.push(...props.material.images)
  if (props.material.video) {
    arrayTemp.push(props.material.video)
  }
  return arrayTemp
})
</script>
<style>
.temp-slide {
  @apply w-max rounded-lg  cursor-pointer m-2;
}
</style>
