<template>
  <div class="flex justify-between w-full">
    <SelaDialouge
      :model-value="modelValue"
      :with-vmodel="true"
      width="auto"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #content="{ close }">
        <div class="p-2 sela-container w-full max-h-[85vh]">
          <v-locale-provider :rtl="useIsRtl()" class="w-full">
            <v-card-title>{{ t('select_branch') }} </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 100px">
              <v-radio-group v-model="selectedBranch" column>
                <v-radio
                  v-for="(branch, index) in branches"
                  :key="index"
                  :value="branch"
                >
                  <template #label>
                    <div>
                      <div class="font-bold">
                        {{ branch.name }}
                      </div>
                      <div class="text-xs">
                        {{ branch.address }}
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div
                class="flex w-full justify-center"
                :class="{ 'cursor-not-allowed': !selectedBranch }"
              >
                <v-btn
                  prepend-icon="mdi-check"
                  class="theme-btn-save"
                  :disabled="!selectedBranch"
                  :class="{ disable: !selectedBranch }"
                  @click="selectBranch(close)"
                >
                  {{ t('order_now') }}
                </v-btn>
                <v-btn
                  prepend-icon="mdi-close"
                  class="theme-btn-close"
                  :disabled="!selectedBranch"
                  :class="{ disable: !selectedBranch }"
                  @click="close"
                >
                  {{ t('close') }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-locale-provider>
        </div>
      </template>
    </SelaDialouge>
  </div>
</template>

<script setup lang="ts">
const selectedBranch = ref<BranchStore | undefined>(undefined)
const emit = defineEmits(['select-branch', 'update:modelValue'])

defineProps<{
  branches?: BranchStore[]
  modelValue: boolean
}>()

const { t } = useI18n()

function selectBranch(close: any) {
  if (selectedBranch.value !== undefined) {
    emit('select-branch', selectedBranch.value)
    close()
  }
}
</script>

<style>
.theme-btn-save {
  @apply bg-nav_color text-text_alt_color !important;
}
.theme-btn-close {
  @apply bg-text_alt_color text-nav_color !important;
}
</style>
