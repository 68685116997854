<template>
  <div class="flex items-center">
    <div class="bg-button_color rounded" @click.prevent="decreaseQuantity">
      <v-icon
        icon="mdi mdi-minus-thick"
        :size="size||24"
        class="block text-text_alt_color cursor-pointer"
        :aria-label="t('increase_item', { name: t('quantity') })"
      />
    </div>

    <div class="mt-1 mx-5 text-xl">
      {{ modelValue }} {{ material.unit || '' }}
    </div>

    <div class="bg-button_color rounded" @click.prevent="increaseQuantity">
      <v-icon
        icon="mdi mdi-plus-thick"
        :size="size||24"
        class="block text-text_alt_color cursor-pointer"
        :aria-label="t('decrease_item', { name: t('quantity') })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const props = defineProps<{
  modelValue: number
  size?: number
  material: Material
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
}>()

const { t } = useI18n()

function decreaseQuantity() {
  if (props.modelValue > 1) {
    let newQuantityValue = props.modelValue
    if ((props.material.step || 0) > 0) {
      newQuantityValue -= props.material.step!
    } else {
      newQuantityValue -= 1
    }
    if (
      props.material.min_quantity !== undefined &&
      props.material.min_quantity !== null
    ) {
      if (newQuantityValue >= props.material.min_quantity) {
        updateQuantity(newQuantityValue)
      }
    } else {
      updateQuantity(newQuantityValue)
    }
  }
}
function increaseQuantity() {
  if (props.material.step !== undefined && props.material.step > 0) {
    updateQuantity(props.modelValue + props.material.step)
  } else {
    updateQuantity(props.modelValue + 1)
  }
}
function updateQuantity(newQuantityValue: number) {
  emit('update:modelValue', newQuantityValue)
}
onMounted(() => {
  if (
    props.material.min_quantity !== undefined &&
    props.material.min_quantity !== null
  ) {
    emit('update:modelValue', props.material.min_quantity)
  }
})
</script>

<style scoped>
.quantity-btn {
  @apply p-1 rounded-lg bg-nav_color text-text_alt_color;
}
</style>
